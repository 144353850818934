var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cart-summary-actions"},[_c('div',{staticClass:"cart-summary-actions-inner"},[_c('div',{staticClass:"card-coupons"},[(_vm.box && _vm.box.hasCardCheckin === 1)?_c('div',{staticClass:"card"},[_c('el-form',{ref:"paybackForm",staticClass:"login-form",attrs:{"status-icon":"","rules":_vm.rules,"model":_vm.paybackForm,"statusIcon":false}},[_c('el-form-item',{attrs:{"prop":"number"}},[_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(_vm.$t('terminal.cart.total.clientcard.headline'))}}),_c('el-input',{directives:[{name:"mask",rawName:"v-mask",value:('XXXX-XXXX-XXXX-XXXX'),expression:"'XXXX-XXXX-XXXX-XXXX'"}],class:[
                { 'correct' : _vm.cardNr === _vm.paybackForm.number.replace(/\-/g, '') }
              ],attrs:{"placeholder":'XXXX-XXXX-XXXX-XXXX',"disabled":"disabled"},model:{value:(_vm.paybackForm.number),callback:function ($$v) {_vm.$set(_vm.paybackForm, "number", $$v)},expression:"paybackForm.number"}}),_c('transition',{attrs:{"name":"dialog-fade","mode":"out-in"}},[(_vm.cardNr === _vm.paybackForm.number.replace(/\-/g, ''))?_c('span',{staticClass:"correct"}):_vm._e()])],1)],1)],1):_vm._e(),_c('div',{staticClass:"coupons"},[_c('el-form',{ref:"voucherForm",staticClass:"login-form voucher-form",attrs:{"status-icon":"","rules":_vm.voucherRules,"model":_vm.voucherForm,"statusIcon":false}},[_c('el-form-item',{attrs:{"prop":"number"}},[_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(_vm.$t('terminal.cart.total.voucher.headline'))}}),_c('el-input',{attrs:{"placeholder":`${_vm.$t('terminal.cart.total.voucher.headline')}`,"disabled":"disabled"},model:{value:(_vm.voucherForm.code),callback:function ($$v) {_vm.$set(_vm.voucherForm, "code", $$v)},expression:"voucherForm.code"}})],1),_c('el-button',{class:[
              'btn-default btn-inverted',
              { 'btn-disabled' : !_vm.voucherForm.code }
            ],attrs:{"type":"primary"},domProps:{"innerHTML":_vm._s(_vm.$t('terminal.cart.total.voucher.redeem'))},on:{"click":function($event){return _vm.addVoucher('voucherForm')},"touchstart":function($event){return _vm.touchStart($event)},"touchend":function($event){return _vm.touchEnd($event);},"touchcancel":function($event){return _vm.touchEnd($event)}}})],1),_c('div',{staticClass:"vouchers"},_vm._l((_vm.vouchers),function(voucher,voucherIndex){return _c('div',{key:`vouchers-voucher-${voucherIndex}`,staticClass:"voucher"},[_c('span',{staticClass:"code",domProps:{"innerHTML":_vm._s(voucher.code)}}),_c('span',{staticClass:"delete",on:{"click":function($event){return _vm.deleteVoucher(voucher.id)},"touchstart":function($event){return _vm.touchStart($event)},"touchend":function($event){return _vm.touchEnd($event);},"touchcancel":function($event){return _vm.touchEnd($event)}}})])}),0)],1)]),_c('div',{staticClass:"pay full-width"},[_c('div',{staticClass:"pay-inner"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"value"},[_c('div',{staticClass:"headline",domProps:{"innerHTML":_vm._s(_vm.$t('terminal.cart.total.infovalue'))}}),_c('div',{staticClass:"price",domProps:{"innerHTML":_vm._s(`${_vm.products.currencySymbol} ${_vm.priceFormat(_vm.products.sum)}`)}})]),_c('div',{staticClass:"coupon"},[_c('div',{staticClass:"headline",domProps:{"innerHTML":_vm._s(_vm.$t('terminal.cart.total.coupon'))}}),_c('div',{staticClass:"price",domProps:{"innerHTML":_vm._s(`${_vm.products.currencySymbol} ${_vm.priceFormat(_vm.sumVouchers)}`)}})])]),_c('div',{staticClass:"total"},[_c('div',{staticClass:"total-desc"},[_c('span',{staticClass:"total-headline",domProps:{"innerHTML":_vm._s(`
                ${_vm.$t('terminal.cart.total.headline')}
                <span class='vat'>${_vm.$t('terminal.cart.total.vat')}</span>
              `)}})]),_c('transition',{attrs:{"name":"scale","mode":"out-in"}},[_c('div',{key:_vm.total,staticClass:"total-number scale-animation"},[_c('span',{domProps:{"innerHTML":_vm._s(`${_vm.products.currencySymbol} ${_vm.priceFormat(_vm.products.sumTotal)}`)}})])])],1),_c('transition',{attrs:{"name":"opacity","mode":"out-in"}},[_c('div',{staticClass:"done"},[_c('el-button',{staticClass:"btn-default btn-done",attrs:{"disabled":_vm.products.items && _vm.products.items.length === 0},domProps:{"innerHTML":_vm._s(`${_vm.$t('terminal.cart.total.paylong')}`)},on:{"click":function($event){return _vm.submitForm('paybackForm')},"touchstart":function($event){return _vm.touchStart($event)},"touchend":function($event){return _vm.touchEnd($event);},"touchcancel":function($event){return _vm.touchEnd($event)}}})],1)]),_c('div',{staticClass:"abort",on:{"click":function($event){return _vm.resetStoreCart()}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('terminal.cart.total.abort'))}})])],1)])]),_c('loading-default',{attrs:{"loadingVisible":_vm.loadingVisible,"headline":_vm.loadingHeadline,"excerpt":_vm.loadingExcerpt}}),_c('loading-default',{attrs:{"loadingVisible":_vm.loadingVoucherVisible,"headline":_vm.loadingVoucherHeadline,"excerpt":_vm.loadingVoucherExcerpt}}),_c('dialog-numpad',{attrs:{"dialogNumpadVisible":_vm.dialogNumpadVisible,"closeNumpad":_vm.closeNumpad}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }